.user-details-container {
  padding: 1rem;
  display: block;
  background-color: white;
  height: 100%;

  .profile-header {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;

    button {
      margin-right: 1rem;

      &:last-child {
        margin: 0;
      }
    }

    .fullname {
      font-weight: bolder;
      font-size: 16px;
    }
  }

  .ant-list-header {
    background-color: lightgray;

    .list-header {
      font-weight: bolder;
    }
  }

  .list-item {
    width: 100%;
    .title {
      padding-right: 1rem;
    }

    .value {
      font-weight: bolder;
    }
  }

  .group-info {
    height: calc(100vh - 400px);
    overflow: hidden;
    
    .group-info-list {
      overflow-y: scroll;
      width: 100%;
    }

    .group-item {
      width: 100%;

      .group-details {
        display: flex;
        flex-direction: column;

        .group-name {
          font-weight: bolder;
        }

        .group-description {
          color: black;
          opacity: 0.5;
        }
      }
    }
  }

  .loader-container {
    height: 500px;
  }

  .ant-alert {
    margin-bottom: 1rem;

    .error {
      color: red;
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}