.mat-card {
  background: white;
  border-radius: 4px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  padding: 16px;
  margin: 16px;
  transition: box-shadow 0.3s ease-in-out;
}
.login-container {
  background-image: url('../../../../public/assets/images/MainBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll;

  .logo-wrapper {
    width: auto;
    height: 350px;
    margin-bottom: 2rem;
    background-color: transparent;

    img {
      height: 100%;
      width: auto;
    }
  }

  .page-title {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 2rem;
    color: white;
  }
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .login-wrapper ,
  .security-message {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  
  .login-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: white;

    .login-form {
      width: 100%;
      padding: 2rem;

      .vertical-padded {
        padding: 1rem 0;
      }

      .no-border-btn {
        border: none;
      }

      .auth-button {
        color: #047D95;
      }
    }

    .registration {
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      width: 100%;
      gap: 1rem;

      &.details {
        padding: 2rem;
      }

      .error {
        display: flex;
        width: 100%;
        padding: 1rem;
        border: 3px solid red;
        color: white;
        background-color: red;
        justify-content: center;
        align-items: center;
      }
      
      .account-details {
        padding: 2rem;

        .btn-group {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 1rem;
        }

        .details-item {
          min-height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: 1rem;
          
          .title,
          .value {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: larger;
            font-weight: bolder;
          }

          .title {
            justify-content: flex-end;
          }

        }
        .password-field {
          width: 100%;
          input {
            background: white;
          }
        }
      }
    }
  }

  .security-message {
    color: white;
    padding: 1rem 3rem;
    font-size: 14px;
    text-align: center;
  }
}


.link {
  font-weight: bolder;
  color: rgb(140, 198, 63);
  text-decoration: underline;
}

.flex-col {
  flex-direction: column !important;
}