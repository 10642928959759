.auto-logout {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-weight: bolder;

  &.danger {
    color: red;
  }
}