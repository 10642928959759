.dashboard-details {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(../../../images/tower.png);
  background-size: cover;
  color: white;
  overflow-y: hidden;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    overflow-y: scroll;
    
    .title {
      color: white;
    }
    
    .topic-container {
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;

      
      .topic {
        width: 100%;
        display: grid;
        grid-template-columns: 130px 1fr;
        
        &:hover {
          background-color: #86a65c24;
        }
        .logo {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          
          img {
            width: 80%;
            height: auto;
            
          }
        }
        
        .details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          justify-items: flex-start;
          width: 100%;
          height: 100%;
          
          label {
            color: #8CC63F;
            font-weight: bolder;
            font-size: 2rem;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
          
          p {
            text-align: left;
            padding: 9;
          }
        }
  
        &.disabled {
          color: #999;
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
  
    }

    p.note {
      b {
        color: #8CC63F;
      }
    }
  }

}