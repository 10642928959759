.second-header {
  height: 50px;
  background-color: rgb(92, 102, 112);
  color: white;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  
  justify-content: space-between;

  nav {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      
      li {
        margin: 0;
        padding: 0 1rem;
        height: 100%;
        list-style: none;
        cursor: pointer;
        
        .active-link {
          color: rgb(40, 44, 104);
          font-weight: bolder;
        }
      }
    }
  }

  .facility-select {
    min-width: 200px;
  }

  .header-link {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-weight: bolder;
    border: 1px solid white;
  }
}