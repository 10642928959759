.top-header {
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px;
  background-color: white;
  gap: 2rem;
  padding: 0 2rem;

  .logos {
    display: flex;
    align-items: center;
    gap: 2rem;
    
    h1 {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-weight: bolder;
    }

    img {
      height: 50px;
      width: auto;
    }
  }

  .gravatar {
    height: 50px;
    border-radius: 50%;
  }

}