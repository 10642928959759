.page-loader {
  height: 100%;
  position: absolute;
  flex-direction: column;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: white, $alpha: 0.5);

  .loader {
    width: 5rem;
    height: 5rem;
  }
}