.user-management {
  padding: 0 1rem;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      padding: 1rem;
      margin: 0;
    }
  }
}

.table-container {
  width: 100%;
  min-height: 300px;
  background: white;
  overflow-y: scroll;
  padding: 1rem;

  .filters {
    padding: 0 1rem;
    min-height: 50px;
    display: flex;
    align-items: center;
    gap: 1rem;

    .search-terms {
      width: 100%;
    }

    .search-by {
      width: 250px;
      margin: 0;
      padding: 0;

      .amplify-label {
        display: none;
      }
    }
  }

  .table-container {
    padding: 0 1rem;
  }

  .pagination {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    gap: 1rem;
  }
}

.modal-content {
  max-height: 80% !important;
  min-width: 30% !important;
  padding: 0;
}