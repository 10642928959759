.dashboard {
  display: flex;
  height: calc(100vh - 160px);
  background-repeat: no-repeat;
  background-image: url(../../../images/map.png);
  background-size: cover;
  color: white;
  overflow-y: scroll;
  position: relative;
  
  .content {
    position: relative;
    display: block;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .topic-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-height: 80%;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      
      .topic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 2rem;

        .logo {
          width: 100px;
          padding: 1rem;
        }
  
        label {
          color: #8CC63F;
          font-weight: bolder;
          font-size: 3rem;
          display: flex;
          height: auto;
          line-height: normal;
          text-align: center;
          cursor: pointer;
        }
  
        &:hover {
          background: #8CC63F;
          color: white;
          cursor: pointer;
  
          label {
            color: white;
          }
        }
  
      }

    }

    p {
      justify-content: center;
      text-align: center;
      font-weight: bolder;
      font-size: medium;
      padding: 0 3rem;
    }
  }
}

.ant-typography {
  color: white;
  font-weight: bolder;
}