.side-nav-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgb(217, 217, 217);
  

  .welcome {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8cc63f;
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.3rem;
  }

  .menu-gravatar {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .gravatar {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }

  .sidebar {
    cursor: pointer;

    .sidebar-item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      border-bottom: 1px solid gray;
      font-weight: bold;

      &:hover {
        background-color: #8cc63f;
        color: white;
      }

    }
    
    .sub-nav {
      margin: 0;
      padding: 0;

      li,
      .link-s {
        list-style: none;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 0 0 2rem;
        border-bottom: 1px solid gray;
        color: black !important;
        font-weight: bold;

        &:hover {
          background-color: #8cc63f;
          color: white;
        }

        a {
          color: black !important;
          font-weight: inherit;
        }
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
  color: gray;
  text-decoration: none;
}