@import "theme.css";
@import "general.css";
@import "components/index.css";

* {
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
}

body {
  font-family: 'Open Sans', sans-serif; /* Body text */
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif; /* Headings */
}

html, body {
  width: 100vw;
  height: 100vh;
  display: block;
  background: white;
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: black;
}

.full-page {
  box-sizing: border-box;
  width: 100vw;
  height: calc(100vh - 50px);
  display: block;
  background-color: rgb(226, 230, 240);
  
  .app-container {
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .top-banner {
      background-color: rgb(40, 44, 104);
      height: 30px;
      position: relative;
    }

    .main-wrapper {
      display: grid;
      grid-template-columns: 250px 1fr;
      height: calc(100vh - 160px);
      

      .side-nav {
        width: 250px;
        height: 100%;
        background-color: rgb(217, 217, 217);
      }

      .main-content {
        width: 100%;
        height: 100%;
        
        .tableau-container {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    background-color: #E8E8E8;
    border: 1px solid lightgray;
    font-weight: bolder;

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: #1f19c9;

    }
    
  }
}

.tableau-container iframe #dashboard-spacer {
  margin: 0 !important;
}

.button-link {
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: blue;

  &:hover {
    text-decoration: underline;
  }
}

.modal-header {
  width: 100%;
  height: 50px;
  padding: 0 2rem;
  font-weight: bolder;
  background-color: #3b396c;
  color: white;
}
.modal-content {
  padding: 0;
  overflow-y: scroll;
  height: calc(70vh - 50px);
  ul {
    margin-left: 2rem;
  }
}

.cp-link {
  color: #1f19c9;
  cursor: pointer;
}

.bold {
  font-weight: bolder;
}

.no-access {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;


  span {
    border: 1px solid lightgray;
    font-size: 2rem;
    padding: 2rem;
    background-color: white;
    font-weight: bolder;
  }
}

a[color='black'] {
  color: black;

  &:hover,
  .active {
    color: white;
  }
}

.text-centered {
  text-align: center;
}